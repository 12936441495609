<template>
  <div>
    <Navbar class="fixed top-0 left-o z-20" mode="solid"/>
    <main class="page-main">
      <div class="section">
        <h1 class="page-title" v-t="'page.account.title'"/>

        <div class="account-tiles-container">
          <router-link
              class="account-tile"
              tag="a"
              to="/account/data"
          >
            <i class="bx bx-user-circle bx-sm"></i>
            <div class="account-tile-text-container">
              <h3 class="account-tile-title" v-t="'page.account.tile.personal_info.title'"/>
              <p class="account-tile-description" v-t="'page.account.tile.personal_info.details'"/>
            </div>
            <i class="bx bx-chevron-right bx-sm ml-auto"></i>
          </router-link>
          <router-link
              class="account-tile"
              tag="a"
              to="/account/login-and-security"
          >
            <i class="bx bx-lock bx-sm"></i>
            <div class="account-tile-text-container">
              
              <h3 class="account-tile-title" v-t="'page.account.tile.login_security.title'"/>
              <p class="account-tile-description" v-t="'page.account.tile.login_security.details'"/>
            </div>
            <i class="bx bx-chevron-right bx-sm ml-auto"></i>
          </router-link>
        </div>
        <ButtonBasic :is-icon="true" class="p-3" :text="$t('cta.logout')" id="logout-btn" @click.native="signOut()" :loading="loading"/>
      </div>
    </main>
    <BottomBar class="layout-bar"/>
    <Footer class="mt-72"/>
  </div>
</template>
<script>
import Navbar from "@/components/layout/Navbar";
import Footer from "@/components/layout/Footer";
import BottomBar from "@/components/layout/BottomBar";
import ButtonBasic from "@/components/buttons/ButtonBasic";
import {mapActions} from "vuex";

export default {
  name: "Account",
  components: {
    ButtonBasic,
    BottomBar,
    Footer,
    Navbar,
  },
  data() {
    return {
      loading:false,
    };
  },
  methods:{
    ...mapActions("auth", ["logout"]),
    async signOut() {
      const res = await this.logout();
      if (!res) return false;
      this.$notify({
        type: "success",
        title: res.data.message,
      });
    },
  },
  beforeCreate() {
    if (localStorage.getItem("is_login") === "true") {
      localStorage.removeItem("is_login");
      location.reload();
    }
  },
  computed: {},
};
</script>
<style scoped>
.page-main {
  @apply w-full px-4 md:px-12;
}

.section {
  @apply relative w-full max-w-screen-md mt-32 transform -translate-x-1/2 left-1/2;
}

.page-title {
  @apply mt-12 mb-8 font-sans text-2xl font-bold text-black-base;
}

.account-tiles-container {
  @apply grid gap-8 sm:grid-cols-12 pb-8;
}

.account-tile {
  @apply flex items-center col-span-6 p-4 sm:px-6;
  @apply border rounded-xl border-grey-base;
}

.account-tile-text-container {
  @apply ml-4;
}

.account-tile-title {
  @apply font-sans text-lg font-semibold text-black-base;
}

.account-tile-description {
  @apply font-sans text-black-lightest text-sm;
}


.layout-bar {
  @apply fixed bottom-0 w-full sm:hidden z-10;
}

#logout-btn{
  @apply w-full inline-flex sm:hidden;
}
</style>